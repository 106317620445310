body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

code {
  font-family: 'Montserrat', sans-serif;
}
