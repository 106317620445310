.App {
  text-align: center;
  background: linear-gradient(to right, #d0ddfd, #9198e5);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0.2em 1.5em rgba(0, 0, 0, 0.1);
  /*padding: 1rem 0;*/
  position: sticky;
  top: 0;
  z-index: 100;

  .h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #0a192f;
  }

  nav {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0.2em 1.5em rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    position: sticky;      
    top: 0;
    z-index: 100;
    /*background-color:rgba(243, 244, 194, 0.15);
    overflow: hidden;
    position: sticky;
    top: 0;
    width: 100%;
    height: 8em;
    z-index: 1000;
    box-shadow: 0 0.4em 1.4em 0 rgba(0, 0, 0, 0.15);*/
    ul{
      list-style: none;
      display: flex;
      justify-content: center;
      padding: 0;
      li { 
        margin: 0 1em;
      
        hover {
          color: black;
          cursor:default;
        }
      }
    }
}


  .nav-content {
    /*max-width: 900px;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;*/

    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-direction: column ;
  }
  
}

.profile-image {
  width: 200px; 
  height: 200px; 
  border-radius: 50%; /* Gör bilden rund */
  border: 5px solid white;
  object-fit: cover; 
  display: block;
  margin: 0 auto;
}

.section {
  padding: 2.5rem 1.25rem;
  max-width: 60rem;
  margin: auto;
}

/* Footer styles */
footer {
  text-align: center;
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 -0.2em 1.5em rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
}

footer p {
  color: #0a192f;
}

a {
  color: black;
  text-decoration-line: none;
  
}

a:hover {
  text-decoration:none;
  text-decoration-color: black;
}

.projects {
  h3{
    text-align: left;
    /* margin-bottom: 0; */
  }
  p{
    text-align: left;
  }
}

.project {
  display: flex; /* Using flexbox for layout */
  align-items: center; /* Vertically centers items in the flex container */
  justify-content: flex-start; /* Aligns items to the start of the flex container */
  padding: 1rem;
  margin: 1rem 0;
  background: #f7f8fd;
  border-radius: 0.5rem;
  box-shadow: 0 0.4em 0.8em rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project:hover,
.project:focus {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background: white;
}


.project-link {
  text-decoration: none; 
  color: inherit;
  display: block; 
}

.project-image {
  max-width: 30%; 
  height: auto;
  margin: 1.25rem;
  border-radius: 0.5rem;
}

.project-desc {
  overflow: hidden;
}

@media (max-width: 600px) {
  .project {
      flex-direction: column; 
      align-items: flex-start; 
      box-shadow: 0 0.4em 0.8em rgba(0, 0, 0, 0.1);
  }
  
  .project-image {
      max-width: 100%; 
      height: auto;
      align-self: center;
  }

  header {
      position: static; /* Makes the header static */
  }

  header h1 {
      font-size: 2rem;
      margin-bottom: 0.5rem; /* Add some space between the title and nav */
  }

  nav ul {
      flex-direction: column;
      align-items: center; /* Center align the nav items */
  }

  nav ul li {
      margin: 10px 0;
  }

  nav ul li a {
      font-size: 1.1rem; 
  }

  /* Adjust the padding and text alignment for sections */
  .section {
      text-align: center; 
      
  }

  .projects {

  }
}